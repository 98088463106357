import Image from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi';

function Navigation({ user }) {

    const [menu, setMenu] = useState(false);

    return (
        <nav className='w-full drop-shadow px-10 bg-slate-900 sticky top-0 z-10 py-3 bg-opacity-90'>
            <div className='flex text-center text-xl'>

                <div className='w-[70%]'>
                    <Link href="/">
                        <Image src="/logo.png" alt="CRM Side Kick" width={40} height={40} />
                    </Link>
                </div>



                <div className='text-md flex justify-end text-right mobile:hidden phone:hidden tablet:hidden laptop:block'>
                    <Link className='px-2 text-white hover:text-cyan-100 transition-all duration-300 hover:font-bold' href="https://www.youtube.com/">Tutorials</Link>
                    <Link className='mx-4 px-4 bg-amber-600 rounded-2xl text-gray-200 font-bold hover:text-amber-100 hover:shadow-md hover:shadow-amber-400 hover:font-bold transition-all duration-200' href={user ? "/app/dashboard" : "/app"}>{user ? "Dashboard" : "Get Started"}</Link>
                </div>



                <div className='laptop:hidden w-[30%] flex items-center justify-end'>
                    <button aria-label="Menu Navigation" onClick={() => setMenu(!menu)} className='p-3 transition-all duration-500 hover:bg-blue-500 rounded-full'>
                        <GiHamburgerMenu color="grey" size={27} />
                    </button>
                    {
                        menu ?
                            <ul className='text-md z-40 fixed top-24 right-4 p-2 bg-blue-950 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-20 border border-gray-100 drop-shadow-2xl'>
                                <li><Link href="https://youtube.com"><span tabIndex={0} className='font-bold py-2 text-white transition-all duration-200 hover:text-blue-500 cursor-pointer'>Tutorials</span></Link></li>
                                <li><Link href={user ? "/app/dashboard" : "/app"}><span tabIndex={1} className='font-bold py-2 text-white transition-all duration-200 hover:text-blue-500 cursor-pointer'>{user ? "Dashboard" : "Get Started"}</span></Link></li>
                            </ul>
                            :
                            null
                    }
                </div>
            </div>
        </nav>
    )
}

export default Navigation
