
import axios from "axios";
import Cookies from 'js-cookie';


const dev = process.env.NODE_ENV !== 'production';
const REACT_APP_COOKIE_KEY = 'CRMSideKick'
const instance = axios.create();
export const authUrl = !dev ? 'https://www.crmsidekick.com' : "http://localhost:3000";


export const GetAPI = (route, auth = true) => Get(`${authUrl}${route}`, auth);
export const PostAPI = (route, body = {}, auth = true) => Post(`${authUrl}${route}`, body, auth);
export const PatchAPI = (route, body = {}, auth = true) => Patch(`${authUrl}${route}`, body, auth);
export const DeleteAPI = (route, auth = true) => Delete(`${authUrl}${route}`, auth);

export function Get(url, auth = true) {
    return new Promise(async resolve => {

        if (auth) {
            const token = Cookies.get(REACT_APP_COOKIE_KEY);
            const headers = { 'Authorization': 'Bearer ' + token };
            try {
                const response = await instance.get(url, { headers });
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(e?.response?.data || e.message);
            }

        } else {
            try {
                const response = await instance.get(url);
                resolve(response.data);
            } catch (e) {
                console.log({ error: e });
                resolve(e?.response?.data || e.message);
            }
        }
    });
}

export function Post(url, body = {}, auth = true) {
    return new Promise(async resolve => {

        if (auth) {
            const token = Cookies.get(REACT_APP_COOKIE_KEY);

            const headers = { 'Authorization': 'Bearer ' + token };


            try {
                const response = await instance.post(url, body, { headers });
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(e?.response?.data || e.message);
            }


        } else {
            try {
                const response = await instance.post(url, body);
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(e?.response?.data || e.message);
            }
        }
    });
}

export function Patch(url, body = {}, auth = true) {
    return new Promise(async resolve => {

        if (auth) {
            const token = Cookies.get(REACT_APP_COOKIE_KEY);
            const ourAppId = process.env.REACT_APP_ID;

            const headers = { 'Authorization': 'Bearer ' + token, 'App': ourAppId };


            try {
                const response = await instance.patch(url, body, { headers });
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(e?.response?.data || e.message);
            }


        } else {
            try {
                const response = await instance.patch(url, body);
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(e?.response?.data || e.message);
            }

        }
    });
}

export function Delete(url, auth = true) {
    return new Promise(async resolve => {

        if (auth) {
            const token = Cookies.get(REACT_APP_COOKIE_KEY);
            //refresh user if necessary

            const ourAppId = process.env.REACT_APP_ID;
            const headers = { 'Authorization': 'Bearer ' + token, 'App': ourAppId };


            try {
                const response = await instance.delete(url, { headers });
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(e?.response?.data || e.message);
            }


        } else {
            try {
                const response = await instance.delete(url);
                resolve(response.data);
            } catch (e) {
                console.log(e);
                resolve(e?.response?.data || e.message);
            }

        }
    });
}


export function isLoggedIn() {
    return Cookies.get(REACT_APP_COOKIE_KEY) ? true : false
}

export function setAccessToken(token){
    Cookies.set(REACT_APP_COOKIE_KEY, token) 
}